<template>
  <div class="publicStyle">
    <p class="publicTitleStyle">{{ title }}</p>
    <p class="downBtn">
      <span @click="downloadGeneralView"
        >下载<i class="el-icon-download downIconClass"></i
      ></span>
    </p>
    <div class="generalViewTable">
      <el-table
        :header-cell-style="generalViewTableHeadClass"
        :row-class-name="rowStyleFunc"
        :data="tableData"
        v-loading="viewLoading"
      >
        <el-table-column
          prop="name"
          align="center"
          :label="!isCategories ? 'TOP5品牌' : '品类'"
        >
        </el-table-column>
        <el-table-column prop="exposure" align="center" label="曝光量">
          <template #default="{ row }">
            {{ sliceDecimal(row.exposure) }}
          </template>
        </el-table-column>
        <el-table-column prop="exposurePct" align="center" label="曝光占比">
        </el-table-column>
        <el-table-column prop="exposureGrowth" align="center" label="曝光增速">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { downloadGeneralView } from "@/api/sov/sovCategory";

export default {
  data() {
    return {
      generalViewTableHeadClass: {
        "background-color": "#F6F8FA",
        "font-size": "14px",
      },
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    timeRange: {
      type: String,
      default: "",
    },
    productId: {
      type: String,
      default: "",
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    viewLoading: {
      type: Boolean,
      default: false,
    },
    isCategories: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    rowStyleFunc({ rowIndex }) {
      if (this.isCategories) {
        return "otherStyle";
      }
      if (rowIndex === 0) {
        return "firstRowStyle";
      } else {
        return "otherStyle";
      }
    },
    downloadGeneralView() {
      const params = {
        duration: this.timeRange,
        id: this.productId,
      };
      downloadGeneralView(params)
        .then(() => {
          this.$message({
            type: "success",
            message: "文件正在下载，请耐心等待",
          });
        })
        .catch(() => {
          this.$message.error("文件下载失败,请联系管理员!");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.publicStyle {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .publicTitleStyle {
    padding-top: 20px;
    padding-left: 20px;
    font-size: 20px;
    color: #4f4f4f;
  }

  .downBtn {
    overflow: hidden;

    span {
      width: 64px;
      height: 28px;
      float: right;
      margin-right: 20px;
      text-align: left;
      padding-left: 10px;
      line-height: 28px;
      background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
      border-radius: 4px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;

      .downIconClass {
        font-size: 16px;
        position: relative;
        top: -1px;
        left: 10px;
      }
    }
  }

  .generalViewTable {
    margin-top: 10px;
  }
}
</style>

<style lang="scss">
.generalViewTable {
  .firstRowStyle {
    td div {
      font-size: 18px;
      color: #393a4d;
      font-weight: 500;
    }
  }

  .otherStyle {
    td div {
      font-size: 14px;
      color: #34355b;
      font-weight: 400;
    }
  }
}
</style>
