<template>
  <div class="publicStyle distribute">
    <p class="publicTitleStyle">{{ title }}</p>
    <div style="float: right; padding-right: 20px">
      <el-radio-group
        v-model="distribute.radio"
        style="margin-right: 10px; float: left; position: relative; top: -5px"
      >
        <el-radio label="percent">占比</el-radio>
        <el-radio label="amount">数量</el-radio>
      </el-radio-group>
      <el-button
        size="mini"
        @click="handleExpansion"
        :class="['spandAll', { isExpandClass: isAllExpand }]"
      >
        <!-- 全部展开 -->
        {{ isAllExpand ? "全部收起" : "全部展开" }}
        <i
          :class="isAllExpand ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
        ></i>
      </el-button>
    </div>
    <div class="distributeTable">
      <el-table
        v-loading="distributeLoading"
        ref="treeTable"
        :row-class-name="distributeRowStyleFunc"
        :data="tableData"
        :tree-props="{ children: 'details' }"
        row-key="id"
        @expand-change="expandChange"
      >
        <el-table-column
          prop="name"
          align="left"
          show-overflow-tooltip
          :width="isHasTotal ? '140' : '200'"
          :label="headerWord"
        >
        </el-table-column>
        <el-table-column
          class="wechat"
          prop="wechat"
          align="center"
          label="微信"
        >
          <template #default="scoped">
            {{
              distribute.radio === "percent"
                ? scoped.row.wechat === null
                  ? "--"
                  : scoped.row.wechat
                : sliceDecimal(scoped.row.wechat)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="weibo" align="center" label="微博">
          <template #default="scoped">
            {{
              distribute.radio === "percent"
                ? scoped.row.weibo === null
                  ? "--"
                  : scoped.row.weibo
                : sliceDecimal(scoped.row.weibo)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="douyin" align="center" label="抖音">
          <template #default="scoped">
            {{
              distribute.radio === "percent"
                ? scoped.row.douyin === null
                  ? "--"
                  : scoped.row.douyin
                : sliceDecimal(scoped.row.douyin)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="xiaohongshu" align="center" label="小红书">
          <template #default="scoped">
            {{
              distribute.radio === "percent"
                ? scoped.row.xiaohongshu === null
                  ? "--"
                  : scoped.row.xiaohongshu
                : sliceDecimal(scoped.row.xiaohongshu)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="bilibili" align="center" label="B站">
          <template #default="scoped">
            {{
              distribute.radio === "percent"
                ? scoped.row.bilibili === null
                  ? "--"
                  : scoped.row.bilibili
                : sliceDecimal(scoped.row.bilibili)
            }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog v-model="showTier" width="45%" append-to-body>
      <img class="imgClass" :src="require('./tier.png')" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { getBrandDistribution } from "@/api/sov/sovBrand";
import { getProductDistribution } from "@/api/sov/sovProduct";
import { getCategoryDistribution } from "@/api/sov/sovCategory";

export default {
  data() {
    return {
      distributeLoading: true,
      distribute: {
        radio: "percent",
      },
      showTier: false,
      tableData: [],
      expansion: [false],
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    isHasTotal: {
      type: Boolean,
      default: true,
    },
    headerWordType: {
      type: String,
      default: "0",
      // 0：brand
      // 1：product
      // 2：productCompare
      // 3: category
      // 4: categoryCompare
    },
  },
  computed: {
    headerWord() {
      if (this.headerWordType === "0") {
        return "TOP品类";
      } else if (this.headerWordType === "1") {
        return "产品";
      } else if (this.headerWordType === "2") {
        return "TOP品类";
      } else if (this.headerWordType === "3") {
        return "TOP5品牌";
      } else if (this.headerWordType === "4") {
        return "品类";
      } else {
        return "TOP品类";
      }
    },
    id() {
      console.log(this.$route.query);
      console.log(this.headerWordType);
      if (JSON.stringify(this.$route.query) === "{}") {
        return "";
      } else if (this.headerWordType === "0") {
        return this.atob(this.$route.query.brandId);
      } else if (this.headerWordType === "1") {
        return this.atob(this.$route.query.productId);
      } else if (this.headerWordType === "2") {
        return this.atob(this.$route.query.products);
      } else if (this.headerWordType === "3") {
        return this.atob(this.$route.query.categoryId);
      } else if (this.headerWordType === "4") {
        return this.atob(this.$route.query.categories);
      } else {
        return this.atob(this.$route.query.brandId);
      }
    },
    timeRange() {
      return this.$route.query.duration;
    },
    isAllExpand() {
      return !this.expansion.includes(false);
    },
  },
  mounted() {
    this.getDistribution();
  },
  methods: {
    handleExpansion() {
      const expanded = this.expansion.includes(false);
      this.tableData.forEach((item) => {
        this.$refs.treeTable.toggleRowExpansion(item, expanded);
      });
    },
    expandChange(row, expanded) {
      console.log(row, expanded);
      let doms = document.querySelectorAll(".el-table__placeholder");
      let that = this;
      doms.forEach((v) => {
        v.classList.add("el-icon-warning-outline");
        v.onclick = function (e) {
          that.showTier = true;
          e.stopPropagation();
        };
      });
      const index = row.id - 1;
      this.expansion[index] = expanded;
    },
    distributeRowStyleFunc({ rowIndex }) {
      if (!this.isHasTotal) {
        return "otherStyle";
      } else if (rowIndex === 0) {
        return "firstRowStyle";
      } else {
        return "otherStyle";
      }
    },
    async getDistribution() {
      this.distributeLoading = true;
      let res;
      if (this.headerWordType === "0") {
        res = await getBrandDistribution({
          duration: this.timeRange,
          id: this.id,
          type: this.distribute.radio,
        });
      }
      if (this.headerWordType === "1" || this.headerWordType === "2") {
        res = await getProductDistribution({
          duration: this.timeRange,
          id: this.id,
          type: this.distribute.radio,
        });
      }
      if (this.headerWordType === "3" || this.headerWordType === "4") {
        res = await getCategoryDistribution({
          duration: this.timeRange,
          id: this.id,
          type: this.distribute.radio,
        });
      }
      let { code, data } = res;
      if (code === 0) {
        this.formatNull(data);
        data.forEach((v) => {
          if (v.name === "total") {
            v.name = "总计";
            delete v.details;
          }
        });
        data.forEach((v, i) => {
          v.id = i + 1;
          if (v.details && v.details.length !== 0 && v.name !== "total") {
            v.details.forEach((k) => {
              // k.name = k.tier;
              k.id = Math.random() * 2 + 1;
            });
            this.expansion[i] = false;
          } else {
            this.expansion[i] = null;
          }
          v.details && v.details.length
            ? (this.expansion[i] = false)
            : (this.expansion[i] = null);
        });
        this.tableData = data;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
      this.distributeLoading = false;
    },
  },
  watch: {
    timeRange(val) {
      if (val) this.getDistribution();
    },
    "distribute.radio"() {
      this.getDistribution();
    },
  },
};
</script>

<style lang="scss" scoped>
.publicStyle {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.distribute {
  margin-bottom: 20px;

  .publicTitleStyle {
    padding-top: 20px;
    padding-left: 20px;
    font-size: 20px;
    color: #4f4f4f;
  }

  .spandAll {
    background-color: #f4f3f3;
    color: #8c98a5;
    border-radius: 4px;
    transition: transform 0.2s ease-in-out;

    .rotate {
      transform: rotate(-90deg);
    }
  }

  .isExpandClass {
    background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
    color: #fff;
  }

  .distributeTable {
    margin-top: 10px;

    .paddingClass {
      display: flex;
      height: 40px;
      line-height: 40px;

      .spanBox {
        flex: 1;
        display: flex;
        justify-content: space-around;
      }

      span {
        display: inline-block;
      }
    }
  }
}

.imgClass {
  width: 100%;
  height: 100%;
  background-size: contain;
}
</style>

<style lang="scss">
.distribute {
  .distributeTable
    .el-table
    .el-table__header-wrapper
    .el-table__header
    thead
    > tr
    > th
    > .cell {
    color: #8c98a5;
  }

  .rowStyle {
    td div {
      font-size: 14px;
      color: #34355b;
      font-weight: 500;
    }
  }

  .firstRowStyle {
    background-color: #f9fcff;

    td div {
      font-size: 18px;
      color: #393a4d;
      font-weight: 500;
    }
  }

  .otherStyle {
    td div {
      font-size: 14px;
      color: #34355b;
      font-weight: 400;
    }
  }

  .el-radio__input.is-checked .el-radio__inner {
    background-color: #fff;
    border-color: #dda634;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #dda634;
  }

  .el-radio__inner:hover {
    border-color: #dda634;
  }

  .el-radio__inner {
    width: 20px;
    height: 20px;
    background-color: #fff;
  }

  .el-radio__inner::after {
    width: 10px;
    height: 10px;
    background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
  }

  .el-radio {
    margin-right: 20px;
  }

  .distributeTable
    .el-table
    .el-table__header-wrapper
    .el-table__header
    thead
    > tr
    > th
    > .cell {
    color: #8c98a5;
  }

  .el-table__expanded-cell[class*="cell"] {
    background-color: #f9fcff;
    padding: 0;
    padding-left: 90px;
  }

  thead .is-left .cell {
    margin-left: 20px;
  }

  tbody .firstRowStyle .is-left .cell {
    margin-left: 20px;
  }

  .el-table__placeholder {
    z-index: 1000;
    cursor: pointer;
  }
}
</style>
